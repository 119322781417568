<script>
//   import Multiselect from 'vue-multiselect'

const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
export default {
    components:{},
    props:['service','ODS','send','pos','estimated','disable','planB'],
    data(){
        return {
            active:false,
            addPersonel:false,
            fields:[{poste:{poste:"Superviseur",toAsk:false,qte:1},Qte:2,checkQte:2,uom:{designation:'Personne'}}],
            selectedMaterials:[{equipment:"",qte:0,amount:0}],
            selectedService:{},
            
            RH:[],
            postes:[],
            retrieveRH:false,
            retrieveEQP:false,
            EQP_amount:0,
            RH_amount:0,
        }
    },
    mounted(){
        this.selectedService = {
                            id:this.service.id,
                            service:this.service.servicable,
                            qte:this.service.quantity,
                            uom:this.service.servicable.uom,
                            amount:this.service.amount,
                            prestQte:this.service.quantity_prest,
                            checkQte:this.service.quantity,
                            checkAmount:0
                        }
        this.getAmount()
    },
    methods:{
        getAmount(){
            var total = 0;
            total = this.selectedService.checkAmount = parseInt(this.selectedService.service.unit_price) * parseInt(this.selectedService.checkQte)
            this.emitData()
            return total;
        },
        emitData(){
            this.$emit('getvalues',this.selectedService)
            this.$emit('getvaluesEmitted',true)
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
               
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> {{service.servicable.name}}</div>
                            <div class="col text-right">
                                <span>Montant Vérifié: {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(getAmount())}}</span>
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <!-- <th>Produit</th> -->
                                    <!-- <th class="text-center">à la demander</th> -->
                                    <th class="text-center">Quantité demandé</th>
                                    <th class="text-center">Unité</th>
                                    <th class="text-center">Quantité envoyé</th>
                                    <th class="text-right">Confirmer Quantité</th>
                                </thead>

                                <tr>
                                    <!-- <th> {{selectedService.service.designation}} </th> -->
                                    <td class="text-center"> {{selectedService.qte}} </td>
                                    <td class="text-center" v-if="selectedService.uom"> {{selectedService.uom.designation}} </td>
                                    <td class="text-center"> {{selectedService.prestQte}} </td>
                                    <td class="text-right" style="width:33%"> <input type="number" class="form-control" min="0" v-model="selectedService.checkQte" @keypress="isNumber($event)" @input="getAmount()"></td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>